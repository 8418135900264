import React from "react";

const RoleBasedAssessmentIframe = ({ iFrameLink = "" }) => {
  return (
    <div className="flex justify-left items-center">
      <iframe
        src={iFrameLink}
        width="900"
        height="1000"
        sandbox="allow-top-navigation allow-scripts allow-modals allow-forms allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"
      />
    </div>
  );
};

export default RoleBasedAssessmentIframe;
