import React, { useState } from "react";
import { useEffect } from "react";

import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import { toast } from "react-toastify";
import { uploadQuestions } from "../../../../../api/jc";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AssessmentIframe from "./AssessmentIframe";

function DriverOfEngagementStepThree() {
  const { state } = useLocation();

  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [iFrameLink, setIFrameLink] = useState('')
  const questions = useSelector(state => state.behaviour.behaviourAssessment);
  const choice = useSelector(state => state.steps.behaviourChoice);


  useEffect(() => {
    if (questions?.length) {
      setMappedQuestions(questions.map(data => data?.question));
    }
  }, [questions])

  const navigate = useNavigate();

  const handelSubmit = () => {

    const formData = []

    choice.forEach(x => {
      formData.push(questions.find(y => y.question === x));
    })

    let temp = {
      assessment: {
        assessment_type: "BehaviorAssessment",
        assessment_data_ordinality: formData
      }
    }
    uploadQuestions(temp).then((res) => {
      toast.success("Updated.")
      navigate('/assessment/values/start')
    });
  }

  React.useEffect(() => {
    let data = localStorage.getItem('accessData');
    data = data && JSON.parse(data)
    console.log(data);
    let code = data?.isBehaviorAssessmentCompleted || ''
    let fName = data?.firstNameAndLastName?.first_name || 'Abc'
    let lName = data?.firstNameAndLastName?.last_name || 'Abc'
    let gender = data?.firstNameAndLastName?.gender === 'male' ? 'm' : 'f'
    let email = data?.email_address || ''
    let iFrameSrc = `/assessment/behavior/DISC/${code}/instrument/?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&completeURL=${window.location.origin}/assessment/behavior/complete/`
    setIFrameLink(iFrameSrc)
  }, []);

  return (
    <>
      <BehaviorStepper step={0} />
      <WhiteBox title="Welcome to Your Behavior Traits Assessment!">

      <p className="text-[#384259] text-base mb-5">
        Are you ready to discover your strengths and identify areas for growth? Let's get started!
      </p>

      <p className="text-[#384259] text-base mb-2">
        <span className="text-[#000] font-bold">How It Works:</span>
      </p>

        <p className="text-[#384259] text-base mb-5">
            <ul className="list-disc pl-10">
                <li>You’ll be presented with four <span className="font-bold">descriptive traits</span> that represent different aspects of your behavior.</li>
                <li>Drag and arrange them from <span className="font-bold">"Most like me"</span> to <span className="font-bold">"Least like me"</span> based on how well each trait describes you.</li>
                <li>Place the trait that best reflects who you are at the top, and the one that feels least relevant at the bottom.</li>
            </ul>
        </p>

        <p className="text-[#384259] text-base mb-5">
          Feel free to take your time—adjust the order until it feels right. Once you're confident in your choices, hit <span className="font-bold">"Submit"</span> to finalize.
        </p>
        
        <p className="text-[#384259] text-base mb-5">
          This is your chance to explore what makes you unique. Let’s begin the journey!
        </p>

        <AssessmentIframe iFrameLink={iFrameLink || ''} />
      </WhiteBox>

      {/* <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate('/assessment/behavior')}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            if (choice.length < mappedQuestions.length) {
              toast.warning("Please Move All Items To Hear!!")
            } else {
              handelSubmit()
            }
          }

          } sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
        >
          Submit
        </Button>
      </div> */}
    </>
  );
}

export default DriverOfEngagementStepThree;
