import React from 'react';
import { ReactComponent as JobRequisition } from '../../../../../assets/JobRequisition.svg';
import { ReactComponent as CandidatesPool } from '../../../../../assets/CandidatesPool.svg';
import { ReactComponent as CandidatesListing } from '../../../../../assets/CandidatesListing.svg';
import { ReactComponent as CandidateComparison } from '../../../../../assets/CandidateComparison.svg';
import { ReactComponent as CreateTest } from '../../../../../assets/CreateTest.svg';
import { ReactComponent as TeamDynamics } from '../../../../../assets/TeamDynamics.svg';
import { ReactComponent as JobListings } from '../../../../../assets/JobListings.svg';
import { ReactComponent as CandidatesContact } from '../../../../../assets/CandidatesContact.svg';
import { ReactComponent as ApplicationReceived } from '../../../../../assets/ApplicationReceived.svg';
import { ReactComponent as SubsciptionBilling } from '../../../../../assets/SubsciptionBilling.svg';
import { Link } from 'react-router-dom';

function ManagerAdmin() {
  const itemList = [
    // {
    //   image: <JobRequisition className='mx-auto' />,
    //   title: 'Job Requisition',
    //   link: '/business/job-requisition',
    // },
    {
      image: <CandidatesPool className='mx-auto' />,
      title: 'Candidates Pool',
      link: '/business/candidate-pool',
    },
    // {
    //   image: <CandidatesListing className='mx-auto' />,
    //   title: 'Candidates Listing',
    //   link: '/business/candidate-list',
    // },
    // {
    //   image: <CandidateComparison className='mx-auto' />,
    //   title: 'Candidate comparison',
    //   link: '/business/compare-candidates',
    // },
    {
      image: <CreateTest className='mx-auto' />,
      title: 'Create Test',
      link: '/business/create-candidate-test',
    },
    {
      image: <TeamDynamics className='mx-auto' />,
      title: 'Team Chemistry',
      link: '/business/team-chemistry',
    },
    // {
    //   image: <JobListings className='mx-auto' />,
    //   title: 'Job Listing',
    //   link: '/business/job-requisition',
    //   state: { tab: 1 },
    // },
    {
      image: <CandidatesContact className='mx-auto' />,
      title: 'Candidates Contact',
      link: '/business/candidate-contacts',
    },
    // {
    //   image: <ApplicationReceived className='mx-auto' />,
    //   title: 'Application Received',
    //   link: '/business/applications-received',
    // },
    {
      image: <SubsciptionBilling className='mx-auto' />,
      title: ' Subsciption & Billing',
      link: '/business/account-settings',
      state: { tab: 1 },
    },
  ];
  return (
    <div className='mt-10'>
      <div className='flex justify-between gap-2 lg:gap-3 flex-wrap'>
        {itemList.map(({ image, title, link, state }) => (
          <Link
            to={link}
            state={state}
            className='w-[45%] md:w-1/4 lg:w-1/5 xl:w-1/6 bg-white p-3 rounded-lg text-center flex flex-col justify-between'
          >
            {image}
            <div className='text-[20px] font-bold mt-2'>{title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ManagerAdmin;
