import React, { useState } from "react";
import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import RoleBasedAssessmentIframe from "./RoleBasedAssessmentIframe";

function RoleBasedCompetenciesStepFive() {
  const [iFrameLink, setIFrameLink] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    let data = localStorage.getItem("accessData");
    data = data && JSON.parse(data);
    let code = data?.isRoleBasedAssessmentCompleted || "";
    let fName = data?.firstNameAndLastName?.first_name || "Abc";
    let lName = data?.firstNameAndLastName?.last_name || "Abc";
    let gender = data?.firstNameAndLastName?.gender === "male" ? "m" : "f";
    let email = data?.email_address || "";
    let aiReportId = data?.assessmentType?.ai_report_id || 208;
    let iFrameSrc = `/assessment/role-based-competencies/AI/${code}?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&ai_report=${aiReportId}&completeURL=${window.location.origin}/assessment/rolebased/complete/&skipIntro=1`;
    setIFrameLink(iFrameSrc);
  }, []);

  return (
    <>
      <BehaviorStepper step={2} />
      <WhiteBox title="Welcome to Your Role-Based Competencies Assessment!">

        <p className="text-[#384259] text-base mb-5">
          This assessment is designed to help you understand your decision-making strengths and areas where you can grow. By exploring your personal, practical, and analytical skills, you’ll gain valuable insights to enhance your performance.
        </p>

        <p className="text-base mb-5 text-[#828282]">
          <span className="text-[#E82127] font-bold">VERY IMPORTANT:</span>{" "}
          Please read these instructions carefully before starting:
        </p>

        <p className="text-[#384259] text-base mb-5">
          The assessment is divided into two parts, each with unique instructions. Follow them closely to ensure your answers reflect your true preferences and values. When you're ready, click “Continue” to begin.
        </p>

        <p className="text-[#384259] text-base mb-2">
          <span className="text-[#000] font-bold">Part 1: Evaluating Importance</span>
        </p>

        <p className="text-[#384259] text-base mb-5">
            You’ll be presented with a series of statements, such as <span className="text-[#000] font-bold">"A new car"</span> or <span className="text-[#000] font-bold">"A token of love."</span> These statements reflect different values or situations.
            <ul className="list-disc pl-10 pt-5">
                <li>Click and drag the statement you believe holds the highest value to the top of the list.</li>
                <li>Continue organizing the rest of the statements from most important at the top to least important at the bottom.</li>
            </ul>
        </p>
        

        <p className="text-[#384259] text-base mb-2">
          <span className="text-[#000] font-bold">Part 2: Measuring Agreement</span>
        </p>

        <p className="text-[#384259] text-base mb-5">
            In this section, you’ll see a new set of statements like <span className="text-[#000] font-bold">"I like my work, and it does me good"</span> or <span className="text-[#000] font-bold">"My work adds to the beauty and harmony of the world."</span> This time, you will rank them based on how much you agree or disagree.
            <ul className="list-disc pl-10 pt-5">
                <li>Start by clicking and dragging the statement you agree with the most to the top of the list.</li>
                <li>Continue ranking the other statements from strong agreement (top) to strong disagreement (bottom).</li>
            </ul>
        </p>
        <p className="text-[#384259] text-base mb-5">
            Feel free to revisit Part 1 and Part 2 if you need to make adjustments before submitting.
        </p>

        <p className="text-[#384259] text-base mb-2">
          <span className="text-[#000] font-bold">Key Reminders:</span>
        </p>

        <p className="text-[#384259] text-base mb-5">
            <ul className="list-disc pl-10">
                <li>In Part 1, focus on how valuable each statement is, regardless of whether it’s positive or negative.</li>
                <li>In Part 2, rank the statements based on your personal level of agreement or disagreement.</li>
            </ul>
        </p>
        <p className="text-[#384259] text-base mb-5">
            Thank you for participating in this assessment. We hope it provides meaningful insights into your strengths and areas for growth, helping you on your journey to personal and professional success.
        </p>

        <div className="hidden">
          <RoleBasedAssessmentIframe iFrameLink={iFrameLink} />
        </div>
      </WhiteBox>
      <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/values/start")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/rolebasedcompetencies/assessment")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
          endIcon={
            <ArrowRightAltIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  fontSize: "30px",
                  color: "white",
                },
              }}
            />
          }
        >
          Continue
        </Button>
      </div>
    </>
  );
}

export default RoleBasedCompetenciesStepFive;
