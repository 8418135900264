import "./common/styles/tailwind.css";
import "./common/styles/main.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import store from "./redux/store";
import { Provider, useDispatch } from "react-redux";
import LoginCandidate from "./common/components/JCComponents/login";
import RegisterCandidate from "./common/components/JCComponents/register";
import LoginBusiness from "./common/components/HRComponents/login";
import RegisterBusiness from "./common/components/HRComponents/register";
import VerifyEmail from "./common/components/JCComponents/verifyEmail";
import ReActiveAccount from "./common/components/JCComponents/reactiveAccount";
import ProfileJC from "./common/components/JCComponents/profile";
import HrPanel from "./common/components/HRComponents/hrPanel";
import CompleteYourProfile from "./common/components/HRComponents/completeYourProfile";
import JcPanel from "./common/components/JCComponents/jcPanel";
import UnAuthorize from "./common/components/sharedComponents/unAuthorize/unAuthorize";
import GoogleLoginPage from "./common/components/sharedComponents/loginWithGoogle/GoogleLoginPage";
import GithubLoginPage from "./common/components/sharedComponents/loginWithGithub/GithubLoginPage";
////////////
// import About from "./containers/landingPage/pages/About";
// import Price from "./containers/landingPage/pages/Price";
// import Security from "./containers/landingPage/pages/Security";
// import TripaBusiness from "./containers/landingPage/pages/TripaBusiness";
// import TripaForJobSeekers from "./containers/landingPage/pages/TripaForJobSeekers";
// import Questions from "./containers/landingPage/pages/Questions";
// import ContactUs from "./containers/landingPage/pages/ContactUs";
// import AboutUs from "./containers/landingPage/pages/AboutUs";
// import ContactSales from "./containers/landingPage/pages/ContactSales";
// import TermsConditions from "./containers/landingPage/pages/TermsConditions";
// import PrivacyPolicy from "./containers/landingPage/pages/PrivacyPolicy";
// import CookieSettings from "./containers/landingPage/pages/CookieSettings";

//jc candidate page
import Dashboard from "./common/components/JCComponents/dashboard";
import BehavioralTraits from "./common/components/JCComponents/behavioralTraits";
import ValueProfile from "./common/components/JCComponents/valueProfile";
import RolebaseProfile from "./common/components/JCComponents/rolebaseProfile";
import JoblistProfile from "./common/components/JCComponents/joblistProfile";
import PersonalBudget from "./common/components/JCComponents/personalBudget";
import SettingsProfile from "./common/components/JCComponents/settingsProfile";
import PersonalStatment from "./common/components/JCComponents/personalStatement";
//bussiness page
import HrDashboard from "./common/components/HRComponents/hrPanel/pages/dashboard/mainDashboard";
import CondidateList from "./common/components/HRComponents/hrPanel/pages/condidateList";
import AccountSettings from "./common/components/HRComponents/hrPanel/pages/accountSettings";
import ManagerAdmin from "./common/components/HRComponents/hrPanel/pages/managerAdmin";
import JobRequisition from "./common/components/HRComponents/hrPanel/pages/jobPostingRequisition";
import CandidatePool from "./common/components/HRComponents/hrPanel/pages/candidatePool";
import CandidateComparison from "./common/components/HRComponents/hrPanel/pages/candidateComparison";
import TeamDynamics from "./common/components/HRComponents/hrPanel/pages/teamDynamics";
import ApplicationsReceived from "./common/components/HRComponents/hrPanel/pages/applicationsReceived";
import CreateCandidateTest from "./common/components/HRComponents/hrPanel/pages/jobPostingRequisition/CreateCandidateTest";
import ContactList from "./common/components/HRComponents/hrPanel/pages/managerAdmin/contactList";
import CandidateAssessment from "./common/components/JCComponents/behaviorTraitsAssessment/steps/CandidateAssessment";
import CandidateAssessmentTime from "./common/components/JCComponents/behaviorTraitsAssessment/steps/CandidateAssessmentTime";
import DriverOfEngagementStepFour from "./common/components/JCComponents/behaviorTraitsAssessment/steps/DriverOfEngagement2";
import DriverOfEngagementStepThree from "./common/components/JCComponents/behaviorTraitsAssessment/steps/DriverOfEngagement1";
import BehaviorTraits from "./common/components/JCComponents/behaviorTraitsAssessment/steps/BehaviorTraits";
import UploadResume from "./common/components/JCComponents/behaviorTraitsAssessment/steps/UploadResume";
import CheckResume from "./common/components/JCComponents/behaviorTraitsAssessment/steps/CheckResume";
import RoleBasedCompetenciesStepFive from "./common/components/JCComponents/behaviorTraitsAssessment/steps/RoleBasedCompetencies1";
import RoleBasedCompetenciesStepSix from "./common/components/JCComponents/behaviorTraitsAssessment/steps/RoleBasedCompetencies2";
import RoleBasedCompetenciesStepSixB from "./common/components/JCComponents/behaviorTraitsAssessment/steps/RoleBasedCompetencies3";
import ChangePassword from "./common/components/JCComponents/ChangePassword";
import Step3Completed from "./common/components/JCComponents/behaviorTraitsAssessment/steps/Step3Completed";
import Step4Completed from "./common/components/JCComponents/behaviorTraitsAssessment/steps/Step4Completed";
import Step6Completed from "./common/components/JCComponents/behaviorTraitsAssessment/steps/Step6Completed";
import Recommendation from "./common/components/JCComponents/recommendation";
import PremiumModal from "./common/components/JCComponents/subscription/PremiumModal";
import WorkforceList from "./common/components/HRComponents/hrPanel/pages/workforce";
import { useEffect, useState } from "react";
import { getPremiumStatus } from "./api/jc";
import { openPremiumModal } from "./redux/actions/premium";
import ProtectedRoute from "./utils/protectedRoute";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'G-FFQ73CJLTQ'
}

TagManager.initialize(tagManagerArgs)

function App() {
  const [isPremium, setIsPremium] = useState(false);
  const getData = async () => {
    try {
      const res = await getPremiumStatus();
      const isPremium = res?.data?.user_pricing_type === "premium";
      setIsPremium(isPremium);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/github/login" element={<GoogleLoginPage />} />
          <Route path="/google/login" element={<GithubLoginPage />} />
          <Route path="/coaching/login" element={<LoginCandidate />} />
          <Route path="/coaching/register" element={<RegisterCandidate />} />
          <Route path="/business/login" element={<LoginBusiness />} />
          {/* <Route path="/business/register" element={<RegisterBusiness />} /> */}
          <Route path="/verify/token/:token" element={<VerifyEmail />} />
          <Route path="/reactive/:token" element={<ReActiveAccount />} />
          <Route path="/resetPassword/:token" element={<ChangePassword />} />
          <Route path="/recommendation/:id" element={<Recommendation />} />
          <Route
            path="/coaching/register/:refferal"
            element={<RegisterCandidate />}
          />
          <Route
            path="/coaching/signup/:token"
            element={<RegisterCandidate />}
          />
          <Route path="/assessment">
            <Route index path="start" element={<CandidateAssessment />} />
            <Route index path="time" element={<CandidateAssessmentTime />} />
            <Route path="behavior/start" element={<BehaviorTraits />} />
            <Route
              path="behavior/finish"
              element={<DriverOfEngagementStepThree />}
            />
            <Route path="behavior/complete" element={<Step3Completed />} />
            <Route
              path="values/start"
              element={<DriverOfEngagementStepFour />}
            />
            <Route path="values/complete" element={<Step4Completed />} />
            <Route
              path="rolebased/start"
              element={<RoleBasedCompetenciesStepFive />}
            />
            <Route
              path="rolebasedcompetencies/assessment"
              element={<RoleBasedCompetenciesStepSix />}
            />
            <Route path="rolebased/complete" element={<Step6Completed />} />
            <Route
              path="rolebased/part2"
              element={<RoleBasedCompetenciesStepSixB />}
            />
          </Route>

          <Route path="/resumeform">
            <Route path="upload" element={<UploadResume />} />
            <Route path="form" element={<CheckResume />} />
          </Route>
          <Route
            path="/complete-your-profile"
            element={<CompleteYourProfile />}
          />

          {/* 
          <Route path="/home" element={<LandingPage />}>
            <Route path="about" element={<About />} />
            <Route path="Price" element={<Price />} />
            <Route path="security" element={<Security />} />
            <Route path="tripa-business" element={<TripaBusiness />} />
            <Route
              path="tripa-for-job-seekers"
              element={<TripaForJobSeekers />}
            />
            <Route path="questions" element={<Questions />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact-sales" element={<ContactSales />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-settings" element={<CookieSettings />} />
          </Route> */}

          <Route path="/coaching" element={<JcPanel />}>
            <Route path="profile" element={<ProfileJC />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="behavioraltraits" element={<BehavioralTraits />} />
            <Route
              element={
                <ProtectedRoute>
                  {" "}
                  <Outlet />{" "}
                </ProtectedRoute>
              }
            >
              <Route path="value-profile" element={<ValueProfile />} />
              <Route path="rolebase-profile" element={<RolebaseProfile />} />
              <Route path="joblist-profile" element={<JoblistProfile />} />
              <Route path="personal-budget" element={<PersonalBudget />} />
              <Route
                path="personal-business-model"
                element={<PersonalStatment />}
              />
            </Route>
            <Route path="profile/settings" element={<SettingsProfile />} />
          </Route>
          {/* end of JC part */}

          <Route path="/business" element={<HrPanel />}>
            <Route path="dashboard" element={<HrDashboard />} />
            <Route path="candidate-list" element={<CondidateList />} />
            <Route path="workforce" element={<WorkforceList />} />
            <Route path="account-settings" element={<AccountSettings />} />
            <Route path="manager-admin" element={<ManagerAdmin />} />
            <Route path="candidate-contacts" element={<ContactList />} />
            <Route path="candidate-pool" element={<CandidatePool />} />
            <Route
              path="compare-candidates"
              element={<CandidateComparison />}
            />
            <Route path="team-chemistry" element={<TeamDynamics />} />
            <Route
              path="applications-received"
              element={<ApplicationsReceived />}
            />
            <Route path="job-requisition" element={<JobRequisition />} />
            <Route
              path="create-candidate-test"
              element={<CreateCandidateTest />}
            />
          </Route>
          <Route path="/unauthorize" element={<UnAuthorize />} />
          <Route path="*" element={<UnAuthorize />} />
          <Route
            path="/"
            element={<Navigate to="/business/login" replace={false} />}
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        // draggable
        pauseOnHover
      />
      <PremiumModal />
    </Provider>
  );
}

const FremiumNavigate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(openPremiumModal());
    navigate("/coaching/profile");
  }, []);
  return null;
};

export default App;
