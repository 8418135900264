import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import { addSkillJC, getHardSkills, addUncategorizeSkills } from "../../../../../api/jc";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
      },
    },
    low: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#828282 !important",
      },
    },
    mid: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#8B5086 !important",
      },
    },
    high: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#7AB794 !important",
      },
    },
  };
});

export default function AddSkills({ data, handleReload, getData }) {
  // const [progress, setProgress] = useState(50);
  const [skills, setSkills] = useState([]);
  const [deletedSkills, setDeletedSkills] = useState([]);
  const [uncategorizeSkills, setUncategorizeSkills] = useState([]);
  const [newSkills, setNewSkills] = useState([]);
  const [defaultSkill, setDefaultSkills] = useState([]);
  const [languageSelectItem, setLanguageSelectItem] = useState("");
  const [languageLevelItem, setLanguageLevelItem] = useState("");
  const progressstyles = useStyles();

  const [initialValues, setInitialValues] = useState({
    name: "",
    level: "",
  });

  // Fetch skills from the database when the component mounts
  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await getHardSkills();
        // Assuming response.data contains the skills in the expected format
        const fetchedSkills = response.data
        .filter(skill => skill.skill && skill.category) // Only include entries with both skill and category
        .map(skill => ({
          value: skill.skill || '',  // Adjust according to your API response
          label: skill.skill || '',  // Use skill for label
          category: skill.category || 'Uncategorize', // Set 'Uncategorize' for missing categories
        }));
        setDefaultSkills(fetchedSkills);
      } catch (error) {
        console.error("Error fetching skills:", error);
        toast.error("Failed to fetch skills from the database.");
      }
    };

    fetchSkills();
  }, []); // Empty dependency array to run only on mount

  const addSkill = () => {
    let item = skills;
    let skillExist = item.find(
      ({ name }) => name === languageSelectItem?.value
    );

    if (skillExist) {
      toast.warning("skill already selected!");
      setLanguageSelectItem("");
    } else if (languageSelectItem && languageLevelItem) {
      if(languageSelectItem.uncategorize==true){
        setUncategorizeSkills([...uncategorizeSkills, {name: languageSelectItem?.value}])
      }
      item = {
        name: languageSelectItem?.value,
        level: languageLevelItem,
      };
      setSkills([...skills, item]);
      setNewSkills((prevSkills) => [...prevSkills, item]);
      setLanguageSelectItem("");
      setLanguageLevelItem("");
    }
  };

  const deleteLangItem = (data) => {
    let item = data.name;
    if (data.id) {
      let deleted_item = { id: data.id };
      setDeletedSkills([...deletedSkills, deleted_item]);
    }

    setNewSkills((prevSkills) =>
      prevSkills.filter((skill) => skill.name !== item)
    );
    setSkills((prevSkills) =>
      prevSkills.filter((skill) => skill.name !== item)
    );
    setDefaultSkills((skills) =>
      skills.map((x) => {
        if (x.value === data.name) {
          return { ...x, isDisabled: false };
        } else {
          return x;
        }
      })
    );
  };

  const handleSubmit = (values) => {
    
    addSkillJC({
      candidateSkill: newSkills,
      candidateDeletedSkill: deletedSkills,
    }).then((res) => {
      addUncategorizeSkills({uncategorizeSkills:uncategorizeSkills})
      handleReload();
      toast.success("Updated.");
    });
  };

  const formik = useFormik({
    initialValues,
    handleSubmit,
    //validate,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    setDefaultSkills(
      defaultSkill.map((x) => {
        if (data.skills.find(({ name }) => name === x.value)) {
          return { ...x, isDisabled: true };
        } else return x;
      })
    );
    setSkills([...data?.skills, ...newSkills]);
  }, [data]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div className="felx flex-col">
            <label className="text-base text-pirmaryBlue">
              Select or type skill
              <Tooltip
                placement="top"
                title={
                  <div className="rounded-md">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Skill
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      Enter your specific technical knowledge or abilities
                      gained through your life experience, formal or informal
                      training, and required for the job you desire. For
                      example, software development language, web design,
                      project management, legal, accounting, and mathematics are
                      some of the skills you could document.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon
                  fontSize="small"
                  className="cursor-pointer ml-1 text-secondaryBalck"
                />
              </Tooltip>
            </label>
            <Autocomplete
              id="skills"
              options={defaultSkill}
              groupBy={(option) => option.category} // Group by category
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option?.value || option
              } // Handle both string and object
              getOptionDisabled={(option) =>
                skills.find(({ name }) => name === option.value) ? true : false
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Select skill" />
              )}
              // Always define inputValue as controlled from the start
              inputValue={languageSelectItem?.value || ""}
              onInputChange={(e, newInputValue) => {
                // Manage the typed value separately from the selected value
                if (newInputValue !== "") {
                  setLanguageSelectItem({
                    value: newInputValue,
                    label: newInputValue,
                    uncategorize: true
                  });
                } else {
                  setLanguageSelectItem(null); // Clear input if no value
                }
              }}
              value={languageSelectItem || null}
              onChange={(e, val) => {
                // Handle selected option or freeSolo input
                if (val && typeof val === "object") {
                  // If a predefined option is selected
                  setLanguageSelectItem(val);
                  formik.setFieldValue("name", val.value);
                } else if (typeof val === "string") {
                  // If custom value is entered
                  const newSkill = { value: val, label: val, uncategorize: true };
                  setLanguageSelectItem(newSkill);
                  formik.setFieldValue("name", newSkill.value);
                } else {
                  // If value is cleared
                  setLanguageSelectItem(null);
                  formik.setFieldValue("name", "");
                }
              }}
              freeSolo // Allow custom input
            />
          </div>
          <div className="felx flex-col">
            <label className="text-base text-pirmaryBlue">
              Proficiency level: (1 - 5)
              <Tooltip
                placement="top"
                title={
                  <div className="rounded-md">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Proficiency level
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      Select your proficiency level on the documented skill from
                      1 - 5. <br />
                      <br /> One (1) being the lowest and five (5) being the
                      highest:
                      <br />
                      <br /> 1 - Elementary proficiency
                      <br />2 - Limited working proficiency
                      <br />3 - Professional proficiency
                      <br />4 - Full Professional proficiency
                      <br />5 - Master proficiency
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon
                  fontSize="small"
                  className="cursor-pointer ml-1 text-secondaryBalck"
                />
              </Tooltip>
            </label>
            <Select
              //     onChange={(e) => {
              //     setSelected({...selected, level: e.target.value})
              // }}
              displayEmpty
              value={languageLevelItem}
              onChange={(e) => {
                setLanguageLevelItem(e.target.value);
                formik.setFieldValue("level", e.target.value);
              }}
              type="text"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
            >
              <MenuItem value="">Select level</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </div>
          <div className="my-2 col-span-2 flex justify-end">
            <SuccessBtn type="button" onClick={addSkill}>
              <span className="font-bold">Add skills</span>
            </SuccessBtn>
          </div>
        </div>
        <div className="font-bold text-2xl">Selected skills</div>
        {skills?.map((val, key) => (
          <div key={key} className="grid grid-cols-8">
            <div className="grid col-span-7">
              <div className="flex flex-col mt-2">
                <div className="flex justify-between py-3">
                  <span className="text-base text-pirmaryBlue">{val.name}</span>
                  <span className="text-base font-bold text-pirmaryBlue">
                    {parseInt(val.level) * 20}%
                  </span>
                </div>
                <div className="-mt-2">
                  <LinearProgress
                    className={
                      val.level <= 2
                        ? progressstyles.low
                        : val.level <= 3
                        ? progressstyles.mid
                        : val.level <= 5
                        ? progressstyles.high
                        : ""
                    }
                    variant="determinate"
                    value={parseInt(val.level) * 20}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 flex justify-end items-center">
              <DeleteRoundedIcon
                className="cursor-pointer"
                onClick={(e) =>
                  deleteLangItem({ id: val?._id, name: val.name })
                }
              />
            </div>
          </div>
        ))}
        <div className="mt-8 col-span-2 flex justify-end">
          <SuccessBtn type="submit">
            <span className="font-bold">Update skills</span>
          </SuccessBtn>
        </div>
      </form>
    </>
  );
}
