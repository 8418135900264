import React, { useState } from "react";
import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import RoleBasedAssessmentIframe from "./RoleBasedAssessmentIframe";

function RoleBasedCompetenciesStepSix() {
  const [iFrameLink, setIFrameLink] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    let data = localStorage.getItem("accessData");
    data = data && JSON.parse(data);
    let code = data?.isRoleBasedAssessmentCompleted || "";
    let fName = data?.firstNameAndLastName?.first_name || "Abc";
    let lName = data?.firstNameAndLastName?.last_name || "Abc";
    let gender = data?.firstNameAndLastName?.gender === "male" ? "m" : "f";
    let email = data?.email_address || "";
    let aiReportId = data?.assessmentType?.ai_report_id || 208;
    let iFrameSrc = `/assessment/role-based-competencies/AI/${code}?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&ai_report=${aiReportId}&completeURL=${window.location.origin}/assessment/rolebased/complete/&skipIntro=1`;
    setIFrameLink(iFrameSrc);
  }, []);

  return (
    <>
      <BehaviorStepper step={2} />
      <WhiteBox title="Role-Based Competencies: Part 1">
        <p className="text-[#384259] text-base mb-5">
          For this part, you’ll evaluate the statements based on their importance, not whether they are good or bad.
        </p>

        <p className="text-[#384259] text-base mb-2">
          <span className="text-[#000] font-bold">How to Rank:</span>
        </p>

        <p className="text-[#384259] text-base mb-5">
            <ul className="list-disc pl-10">
                <li>Drag the statement that resonates the most with you to the top of the list.</li>
                <li>Continue arranging the rest from most important to least important, with the highest value at the top and the lowest at the bottom.</li>
            </ul>
        </p>

        <p className="text-[#384259] text-base mb-5">
          Take your time to think about each statement. When you're happy with your ranking, click <span className="text-[#000] font-bold">“View Part 2”</span> to proceed to the next section.
        </p>

        <h2 className="text-[28px] leading-8 font-bold mb-7">
          Role-Based Competencies: Part 2
        </h2>

        <p className="text-[#384259] text-base mb-5">
          In this section, you’ll evaluate the statements based on how much you agree or disagree with them.
        </p>

        <p className="text-[#384259] text-base mb-2">
          <span className="text-[#000] font-bold">How to Rank:</span>
        </p>

        <p className="text-[#384259] text-base mb-5">
            <ul className="list-disc pl-10">
                <li>Start by clicking and dragging the statement you agree with the most to the top.</li>
                <li>Organize the rest from strong agreement (top) to strong disagreement (bottom).</li>
            </ul>
        </p>

        <p className="text-[#384259] text-base mb-5">
          Once you’re satisfied with your rankings, click <span className="text-[#000] font-bold">“DONE”</span> to complete the assessment.
        </p>

        <RoleBasedAssessmentIframe iFrameLink={iFrameLink} />
      </WhiteBox>
      <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/rolebased/start")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
      </div>
    </>
  );
}

export default RoleBasedCompetenciesStepSix;
