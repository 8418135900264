import React, { useState } from "react";

import { Link } from "react-router-dom";
import SideBarHr from "../sideBarHr/SideBarHr";
import SignOutModal from "../../../sharedComponents/signOutModal";

function WrapperComponentHr({ children, title }) {
  const [openSignOut, setOpenSignOut] = React.useState(false);
  const [openSide, setopenSide] = useState(false);
  return (
    <div className="flex flex-col min-h-[100vh]">
      <SideBarHr
        openSide={openSide}
        setopenSide={setopenSide}
        title={title}
        openSignOut={openSignOut}
        setOpenSignOut={setOpenSignOut}
      />
      <div
        className={`${
          !openSide && "lg:ml-[280px]"
        }  transition-all  mt-20 mb-10 px-5 lg:px-16`}
      >
        <>{children}</>
      </div>

      <div
        className={`bg-white flex sm:flex-row flex-col items-center
       justify-between h-20% min-h-[50px] px-8 mt-auto ${
         !openSide && "lg:ml-[280px]"
       }`}
      >
        <p className="text-[#828282] order-2 sm:order-1 mb-[15px] mt-[5px] sm:my-0">
          Copyright ©2021, TRIPA HC and TRIPA Inc. - All rights reserved.
        </p>
        <div className="flex sm:flex-row flex-col items-center order-1 sm:order-2 mt-[15px] sm:my-0">
          <a href="https://tripa.ai/#contactus" target="_blank" rel="noreferrer" className="mx-2 my-1 sm:my-0 text-[#828282] hover:text-success hover:underline ">
            Support
          </a>
          <a href="https://tripa.ai/general-terms-conditions/" target="_blank" rel="noreferrer" className="mx-2 my-1 sm:my-0 text-[#828282] hover:text-success hover:underline">
            Terms & Conditions
          </a>
          <a href="https://tripa.ai/privacy-policy/" target="_blank" rel="noreferrer" className="mx-2 my-1 sm:my-0 text-[#828282] hover:text-success hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>

      <SignOutModal open={openSignOut} setOpen={setOpenSignOut} />
    </div>
  );
}

export default WrapperComponentHr;
