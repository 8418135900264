import React, { useState } from "react";
import Profile from "../../../sharedComponents/profile/Profile";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as TripalogoLight } from "../../../../assets/tripalogowhite.svg";
import { ReactComponent as Collapssidebar } from "../../../../assets/collapssidebar.svg";
import { ReactComponent as Dashboardicon } from "../../../../assets/dashboardicon.svg";
import { ReactComponent as ManagerAdminicon } from "../../../../assets/managerAdminIcon.svg";
import { ReactComponent as JobRequisitionicon } from "../../../../assets/jobRequisitionIcon.svg";
import { ReactComponent as CompareCondidatesicon } from "../../../../assets/compareCandidatesIcon.svg";
import { ReactComponent as CandidateListicon } from "../../../../assets/candidateListIcon.svg";
import { ReactComponent as CondidatePoolicon } from "../../../../assets/candidatePoolicon.svg";
import { ReactComponent as Settingsicon } from "../../../../assets/settingsicon.svg";
import { ReactComponent as SignOutIcon } from "../../../../assets/signOutIcon.svg";
import { getHRProfile } from "../../../../../api/hr";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

const barIconStyle = makeStyles((theme) => {
  return {
    root: {
      width: "32px!important",
      height: "32px!important",
      cursor: "pointer",
      position: "relative",
    },
  };
});
export default function SideBarHr({
  setopenSide,
  openSide,
  title,
  setOpenSignOut,
  openSignOut,
}) {
  const [allData, setAllData] = useState({});
  const user = useSelector((state) => state.user);

  const getProfileData = async () => {
    try {
      getHRProfile().then((res) => {
        setAllData(res.data);
        console.log(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getProfileData();
  }, []);

  const classes = barIconStyle();
  return (
    <div>
      <div
        className={`fixed top-0 z-10 bg-white h-16 shadow-md transition-all right-0 ${
          !openSide ? "w-[calc(100%-280px)]" : "w-full"
        }`}
      >
        {openSide && (
          <DehazeIcon
            className={classes.root + " shadow-lg rounded-md top-4 left-4"}
            onClick={() => setopenSide((prev) => !prev)}
          />
        )}
        <div className="flex justify-between w-[calc(100%-128px)] h-full items-center absolute top-0 mx-16">
          <div className=" items-center font-bold relative">
            {
              (() => {
                  if (title === 'TEAM DYNAMICS')
                      return 'TEAM CHEMISTRY'
                  else
                      return <>{title}</>
              })()
            }  
          </div>
          <div className="">
            {/* <Stack spacing={4} direction="row" sx={{ color: "action.active" }}>
              <Badge color="success" badgeContent={99}>
                <NotificationsIcon />
              </Badge>
            </Stack> */}
          </div>
        </div>
      </div>
      {!openSide && (
        <div
          className="fixed lg:hidden top-0 left-0 right-0 bottom-0 bg-gray-200 z-20"
          onClick={() => setopenSide((prev) => !prev)}
        ></div>
      )}
      <div
        className={`w-[280px] bg-[#1C1D1F] top-0 transition-all min-h-screen px-3 py-6 z-30 fixed h-screen overflow-y-auto ${
          openSide ? "-left-[280px]" : "left-0"
        }`}
      >
        <div className="flex justify-between z-30">
          <Link to="/business/dashboard">
            <TripalogoLight style={{ width:"100%", height:"100%"}}/>
          </Link>
          <Collapssidebar
            className={`cursor-pointer relative top-2 ${
              openSide ? "rotate-180" : "rotate-0"
            } transition-all`}
            onClick={() => setopenSide((prev) => !prev)}
          />
        </div>
        <div className="my-5">
          <Profile
            alt="profile"
            src={allData.company_logo_upload_path}
            name={allData?.company_name}
            email={allData?.company_email}
          />
        </div>
        {/*  */}
        {/* <div> */}
        <div>
          <ul>
            <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The dashboard's primary purpose is to provide a snapshot of your workforce outsourcing status. It will display information such as candidates' behavior traits, matching skills and competencies, and values, among other data points important in your hiring decision-making process.</div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/dashboard"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <Dashboardicon className="h-5 w-5 mr-2" />
                Dashboard
              </NavLink>
            </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Provides shortcuts to access TRIPA Business features such as job requisition, team chemistry, candidate listing, candidate comparison, application received, and more. </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/manager-admin"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <ManagerAdminicon className="h-5 w-5 mr-2" />
                Manager admin
              </NavLink>
            </li>
            </Tooltip>
            {/* <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Is the official and approved hiring manager's job vacancy. The job requisition enables HR and the hiring manager to enter company details, performance expectations, skills, and competencies required to be successful in the role. Once the job requisition is filled out, it can be posted on the TRIPA platform.</div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/job-requisition"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <JobRequisitionicon className="h-5 w-5 mr-2" />
                Job requisition
              </NavLink>
            </li>
            </Tooltip> */}
            {/* <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Candidate comparison provides you with a psychographic view of each candidate, enabling you two compare all the candidates for a specific position based on the same data points, enhancing your hiring decision-making process and reducing hiring mistakes.                  </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/compare-candidates"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <CompareCondidatesicon className="h-5 w-5 mr-2" />
                Compare candidates
              </NavLink>
            </li>
            </Tooltip> */}
            {/* <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    It provides you with the list of candidates that have passed the filter of meeting the role-based competencies, skills, education, and experience. It also offers additional psychographic data, such as preferred behavior traits and values.      
                                </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/candidate-list"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <CandidateListicon className="h-5 w-5 mr-2" />
                Candidate list
              </NavLink>
            </li>
            </Tooltip> */}
            <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Workforce within the TRIPA platform refers to a comprehensive feature that presents a roster of employees who have established profiles and are actively engaged or readily available for work. This feature is designed to offer a holistic, 360-degree psychographic analysis of each employee, providing in-depth insights into various professional parameters.                  </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/workforce"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <CandidateListicon className="h-5 w-5 mr-2" />
                Workforce
              </NavLink>
            </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    It enables you to create a pool of candidates that passed TRIPA filters, and you approved. The candidate pool allows you to organize your job requisition candidates based on the job ID or vacancy name.</div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/candidate-pool"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <CondidatePoolicon className="h-5 w-5 mr-2" />
                Candidate pool
              </NavLink>
            </li>
            </Tooltip>
            {/* <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                   Provides a list of the top 5 candidates that applied for the job requisition and passed TRIPA filters. It also displayed the status of the application, accepted and rejected. </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/applications-received"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <CandidateListicon className="h-5 w-5 mr-2" />
                Applications received
              </NavLink>
            </li>
            </Tooltip> */}
            <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    It enables you to identify your team's behavior traits and other psychological forces influencing their performance. It also provides you with the insights to help you maximize each team member's inborn talents to reach individual and group peak performance. </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/team-chemistry"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <CandidateListicon className="h-5 w-5 mr-2" />
                Team chemistry
              </NavLink>
            </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Account Settings enables you to update your account basic details, upload a profile picture, change passwords, and managing your subscription. </div>
                }
                arrow
              >
            <li className="rounded-lg mb-2">
              <NavLink
                to="/business/account-settings"
                className={({ isActive }) =>
                  "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                  (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                }
              >
                <Settingsicon className="h-5 w-5 mr-2" />
                Account settings
              </NavLink>
            </li>
            </Tooltip>
            <li className="rounded-lg mb-2">
              <Link
                to="#"
                className={`w-full p-3 flex rounded-md text-white transition-all text-sm nav-link bg-[#1c1d1f] "
                `}
                onClick={() => setOpenSignOut(true)}
              >
                <SignOutIcon className="h-5 w-5 mr-2" />
                Sign out
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
