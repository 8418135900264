import NotificationsIcon from "@mui/icons-material/Notifications";
import React, { useEffect, useState } from "react";
import Avatartest2 from "../../../assets/avatartest.svg";
import Modal from "../../sharedComponents/modal/Modal";
import AboutMe from "./aboutMe";
import Certificates from "./certificates";
import Educations from "./educations";
import EditprofileComponent from "./modalCompnents/editprofile";
// import Recommendations from "./recommendations";
// import Refferals from "./refferals";
import RightPart from "./rightPart";
import Trainings from "./trainings";
import WorkExperinces from "./workExperinces";
import { getByUserId, getPremiumStatus } from "../../../../api/jc";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../redux/actions/user";
import { Avatar } from "@mui/material";

export default function ProfileJC() {
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editInfo, setEditInfo] = useState(false);
  const [allData, setAllData] = useState({});
  const [result, setResult] = useState([]);
  const user = useSelector(state => state.user);

  const [graphData, setGraphData] = useState([]);
  const [roleBasedCompetanceAssessment, setRoleBasedCompetanceAssessment] =
    useState([]);
  const [percent, setpercent] = useState("");
  const dispatch = useDispatch();
  const getData = async () => {
    getByUserId().then((res) => {
      setAllData(res.data);
      dispatch(updateUser(res.data));
      let temp3 = [];
      let temp4 = 0;
      // console.log(data)
      setRoleBasedCompetanceAssessment(
        res?.data?.assessments.filter(
          (i) =>
            i.assessment.assessment_type === "RoleBasedCompetanceAssessment"
        )[0]?.assessment?.result
      );
      res?.data?.assessments
        .filter(
          (i) =>
            i.assessment.assessment_type === "RoleBasedCompetanceAssessment"
        )[0]
        ?.assessment?.result?.map((item) => {
          temp3.push(Number(item?.value));
        });
      res?.data?.assessments
        .filter(
          (i) =>
            i.assessment.assessment_type === "RoleBasedCompetanceAssessment"
        )[0]
        ?.assessment?.result?.forEach((item) => {
          temp4 += Number(item?.value);
        });
      setResult(
        res?.data?.assessments.filter(
          (i) => i.assessment.assessment_type === "BehaviorAssessment"
        )?.[0]?.assessment?.result
      );
      setGraphData(temp3);
      setpercent(temp4);
    });
    const res = await getPremiumStatus()
    const isPremium = res?.data?.user_pricing_type === "premium"
    setIsPremium(isPremium)
    setIsLoading(false)
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        width: "80px",
        height: "80px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="flex justify-start items-start md:justify-between flex-col md:flex-row">
        <div className="flex justify-start">
          {user?.candidate_picture_upload_path ? (
            <img
              alt={'profile'}
              src={
                user.candidate_picture_upload_path
                  ? user.candidate_picture_upload_path
                  : Avatartest2
              }
              className='mx-auto rounded-full h-16 w-16'
            />
          ) : (
            <Avatar {...stringAvatar(`${user?.first_name || ''} ${user?.last_name || ''}`)} />
          )}
          <div className="ml-4 flex flex-col items-center justify-center">
            <h3 className="text-pirmaryBlue font-bold text-[18px]">
              Welcome back, {user?.first_name} !
            </h3>
            {/* <p className="text-secondaryBalck flex">
              <NotificationsIcon />
              <span>You have 2 new notifications</span>
            </p> */}
          </div>
        </div>
        {/* <div className="flex mt-2 md:mt-0 flex-col justify-center items-center">
          <SuccessBtn onClick={() => setEditInfo(true)}>
            <EditRoundedIcon className="mr-2" />
            <span>Edit Profile</span>
          </SuccessBtn>
        </div> */}
      </div>
      <div className="grid grid-cols-3 gap-6 pb-24">
        <div className="col-span-3 md:col-span-2">
          <AboutMe
            handleClickEdit={() => {
              setEditInfo(true);
            }}
          />
          <WorkExperinces
            handleReload={() => {
              getData();
            }}
          />
          <Educations
            data={allData}
            handleReload={() => {
              getData();
            }}
          />
          {/* For Recommendations section */}
          {/* <Recommendations
            data={allData}
            handleReload={() => {
              getData();
            }}
          /> */}
          <Certificates
            data={allData}
            handleReload={() => {
              getData();
            }}
          />
          <Trainings
            data={allData}
            handleReload={() => {
              getData();
            }}
          />
          {/* <Refferals
            data={allData}
            handleReload={() => {
              getData();
            }}
          /> */}
        </div>
        <div className="col-span-3 md:col-span-1">
          <RightPart
            isPremium={isPremium}
            isLoading={isLoading}
            percent={percent}
            roleBasedCompetanceAssessment={roleBasedCompetanceAssessment}
            graphData={graphData}
            result={result}
            data={allData}
            handleReload={() => {
              getData();
              setEditInfo(false);
            }}
          />
        </div>
      </div>

      <Modal
        title="Change profile summary"
        open={editInfo}
        setOpen={setEditInfo}
        mdUpWidth={550}
      >
        <EditprofileComponent
          handleReload={() => {
            getData();
            setEditInfo(false);
          }}
        />
      </Modal>
    </>
  );
}
